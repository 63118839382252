var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.tableContainerClasses},[_c('table',{class:['ck-table',_vm.modalTableBool?'modal':'', _vm.tableClasses],attrs:{"id":_vm.idTabla}},[_c('thead',{class:_vm.tableHeaderClasses},[_c('tr',{class:_vm.tableRowClasses},[(_vm.selectable)?_c('th',{staticStyle:{"width":"115px !important"}},[_c('v-checkbox',{staticClass:"py-0 my-1",attrs:{"hide-details":""},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}})],1):_vm._e(),_vm._l((_vm.columns),function(column){return _c('th',{key:column.name,class:_vm.headerClasses(column),style:('width: ' + column.width + '%'),attrs:{"scope":"col"},on:{"click":function($event){column.filterable ? _vm.sort(column) : null}}},[_c('div',{staticClass:"align-center",style:(("display: flex; justify-content: " + (column.align == null? 'left' : column.align) + "; margin-top:auto; margin-bottom:auto;"))},[(column.showTooltip == true)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(column.label)+" ")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(column.mensajeTooltip)}})])]:[_vm._v(" "+_vm._s(column.label)+" ")],(column.filterable)?[(
                  column.filterable &&
                    column.name == _vm.currentSort &&
                    _vm.dir == 'asc'
                )?_c('div',{staticClass:"filter align-center pt-1"},[_c('i',{staticClass:"material-icons"},[_vm._v("expand_less")])]):_c('div',{staticClass:"filter align-center pt-1"},[_c('i',{staticClass:"material-icons"},[_vm._v("expand_more")])])]:_vm._e()],2)])})],2)]),_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }